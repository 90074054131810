import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Layui from '@layui/layui-vue'
import '@layui/layui-vue/lib/index.css'
import TDesign from 'tdesign-vue-next';
import 'tdesign-vue-next/es/style/index.css';

createApp(App).use(store).use(router).use(ElementPlus).use(TDesign).use(Layui).mount('#app')


